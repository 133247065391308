import styled, { keyframes } from 'styled-components'

export const sharpenAnimation = keyframes`
  0% {
    backdrop-filter: blur(10px);
  }
  100% {
    backdrop-filter: blur(0px);
  }
`

export default styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white.alpha(0.6).hex()};
  animation: ${sharpenAnimation} 0.6s;
`
