import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import GooglePlayBadgeUrlPL from 'src/assets/app_store_badges/google-play-badge_pl.svg'
import GooglePlayBadgeUrlEN from 'src/assets/app_store_badges/google-play-badge_en.svg'
import GooglePlayBadgeUrlDE from 'src/assets/app_store_badges/google-play-badge_de.svg'

import AppStoreBadgeUrlPL from 'src/assets/app_store_badges/appstore-badge_pl.svg'
import AppStoreBadgeUrlEN from 'src/assets/app_store_badges/appstore-badge_en.svg'
import AppStoreBadgeUrlDE from 'src/assets/app_store_badges/appstore-badge_de.svg'
import { breakpoint } from 'src/theme/grid'

export const MobileAppStoreBadgeBtn = ({ app, locale, platform }) => {
  const localizedBadgeImageUrl = {
    pl: {
      android: GooglePlayBadgeUrlPL,
      ios: AppStoreBadgeUrlPL
    },
    en: {
      android: GooglePlayBadgeUrlEN,
      ios: AppStoreBadgeUrlEN
    },
    de: {
      android: GooglePlayBadgeUrlDE,
      ios: AppStoreBadgeUrlDE
    },
    ru: {
      android: GooglePlayBadgeUrlDE,
      ios: AppStoreBadgeUrlDE
    }
  }

  const storeUrl = {
    exchange: {
      ios: 'https://apps.apple.com/app/e-containers-gie%C5%82da/id1569031248',
      android:
        'https://play.google.com/store/apps/details?id=pl.econtainers.exchange'
    },
    driver: {
      ios: 'https://apps.apple.com/app/e-containers-eu-kierowca/id1556615822',
      android:
        'https://play.google.com/store/apps/details?id=pl.econtainers.driver'
    }
  }

  return (
    <React.Fragment>
      <a href={storeUrl[app][platform]}>
        <MobileStoreBadge src={localizedBadgeImageUrl[locale][platform]} />
      </a>
      {/* <a href='https://apps.apple.com/mn/app/e-containers-gie%C5%82da/id1569031248'>
                <MobileStoreBadge src={getAppStoreBadge(intl.locale)} />
            </a> */}
    </React.Fragment>
  )
}

const MobileStoreBadge = styled.img`
  display: flex;
  height: 82px;
  width: auto;
  margin-left: 0;
  margin-bottom: 0.5rem;
  ${breakpoint.sm`
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    // margin-bottom: 3.0rem;
  `}
`
