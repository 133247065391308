import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { isNil } from 'ramda'
import { isFalsy } from 'ramda-adjunct'
import moment from 'moment'
import MUICalendar from '@material-ui/icons/Today'
import MUIArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import MUIArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { useIntl } from 'gatsby-plugin-intl'
import { DATE_API_FORMAT, DATE_FORMAT } from 'src/ducks/consts'

const DatesRange = ({
  className,
  dateFromName,
  dateFromValue,
  dateFromLabel,
  dateToName,
  dateToValue,
  dateToLabel,
  onChange
}) => {
  const [selectedDate, setSelectedDate] = useState(dateFromName)
  const intl = useIntl()

  const renderDay = (
    day,
    calendarSelectedDate,
    dayInCurrentMonth,
    dayComponent
  ) => {
    const currentDate = day.format(DATE_API_FORMAT)
    const isToday = day.isSame(moment(), 'day')
    const isSelected = [dateFromValue, dateToValue].includes(currentDate)
    const rangeIsNotNil = !isNil(dateFromValue) && !isNil(dateToValue)
    const isMarked =
      rangeIsNotNil &&
      day.isSameOrAfter(dateFromValue) &&
      day.isSameOrBefore(dateToValue)

    return (
      <CalendarDay selected={isSelected} marked={isMarked} today={isToday}>
        {dayComponent}
      </CalendarDay>
    )
  }

  const handleDateChange = date => {
    selectedDate === dateFromName && setSelectedDate(dateToName)
    onChange(selectedDate, date.format(DATE_API_FORMAT))
  }

  return (
    <div className={className}>
      <DatesRow>
        <DateBox
          hasValue={!isNil(dateFromValue)}
          selected={selectedDate === dateFromName}
          onClick={() => setSelectedDate(dateFromName)}
          value={dateFromValue}
          label={dateFromLabel}
        />
        <Spacer />
        <DateBox
          hasValue={!isNil(dateToValue)}
          selected={selectedDate === dateToName}
          onClick={() => setSelectedDate(dateToName)}
          value={dateToValue}
          label={dateToLabel}
        />
      </DatesRow>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={intl.locale}
      >
        <CalendarStyled>
          <Calendar
            date={isFalsy(dateFromValue) ? moment() : moment(dateFromValue)}
            onChange={handleDateChange}
            format={DATE_FORMAT}
            renderDay={renderDay}
            leftArrowIcon={<CalendarLeftArrow />}
            rightArrowIcon={<CalendarRightArrow />}
            shouldDisableDate={false}
          />
        </CalendarStyled>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default styled(DatesRange)`
  display: flex;
  flex-direction: column;
`

const CalendarIcon = styled(MUICalendar)`
  && {
    font-size: 1.1rem;
  }
  margin-right: 0.6rem;
  transition: transform 0.3s;
  opacity: 0.7;
  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.apple.hex()};
    `}
`

const DateBoxComponent = ({
  className,
  opened,
  onClick,
  selected,
  value,
  label
}) => (
  <div className={className} onClick={onClick}>
    <CalendarIcon opened={opened} selected={selected} />
    <span>{isFalsy(value) ? label : moment(value).format(DATE_FORMAT)}</span>
  </div>
)

const DateBox = styled(DateBoxComponent)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.ghost.hex()};
  color: ${({ theme }) => theme.colors.black.hex()};
  padding: 0 0.6rem;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s;
  border-radius: 2px;
  font-size: 0.875rem;
  ${({ selected, hasValue }) =>
    selected &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.apple.hex()};
      color: ${({ theme }) =>
        hasValue ? theme.colors.apple.hex() : theme.colors.black.hex()};
      box-shadow: 0 0 10px -4px ${({ theme }) => theme.colors.apple.hex()};
    `}
  & > span {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 30px);
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.apple.hex()};
    color: ${({ theme }) => theme.colors.apple.hex()};
  }
`

export const DatesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.8rem;
  margin-bottom: 0.6rem;
`

export const Spacer = styled.span`
  display: flex;
  width: 25px;
`

export const CalendarStyled = styled.div`
  display: flex;
  flex-direction: column;
  .MuiPickersCalendarHeader-dayLabel,
  .MuiPickersDay-day {
    margin: 0;
    width: 47px;
    height: 40px;
  }
  .MuiPickersCalendarHeader-dayLabel {
    color: ${({ theme }) => theme.colors.black.alpha(0.7).hex()};
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MuiPickersCalendarHeader-transitionContainer .MuiTypography-root {
    color: ${({ theme }) => theme.colors.black.hex()};
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: capitalize;
  }
`

export const CalendarDay = styled.span`
  display: flex;
  position: relative;
  .MuiIconButton-label {
    color: ${({ theme }) => theme.colors.black.hex()};
    font-family: OpenSans;
  }
  .MuiPickersDay-daySelected {
    background-color: transparent;
  }
  .MuiIconButton-root {
    border-radius: 0;
  }

  ${({ marked }) =>
    marked &&
    css`
      .MuiPickersDay-day {
        background-color: ${({ theme }) =>
          theme.colors.easternBlue.alpha(0.15).hex()};
        border-radius: 0;
        .MuiIconButton-label {
          color: ${({ theme }) => theme.colors.black.hex()};
        }
      }
    `}
  ${({ selected }) =>
    selected &&
    css`
      .MuiPickersDay-day {
        background-color: ${({ theme }) => theme.colors.easternBlue.hex()};
        border-radius: 4px;
        .MuiIconButton-label {
          color: white;
        }
      }
    `}
  ${({ today }) =>
    today &&
    css`
      &:after {
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        border-radius: 50%;
        left: calc(50% - 2px);
        bottom: 15%;
        background-color: ${({ theme }) => theme.colors.easternBlue.hex()};
      }
    `}
`

const CalendarLeftArrow = styled(MUIArrowLeft)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black.hex()};
`

const CalendarRightArrow = styled(MUIArrowRight)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black.hex()};
`
