import React from 'react'
import { graphql } from 'gatsby'
import { path, map } from 'ramda'
import {
  findFluidOrFixedInEdgesByFileName,
  findSingleImageFluidOrFixed
} from 'src/utils/helpers'

import {
  Link,
  FormattedMessage,
  FormattedHTMLMessage,
  useIntl
} from 'gatsby-plugin-intl'
import { useSelector, useDispatch } from 'react-redux'
import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'
import routes from 'src/utils/routes'
import { Button } from 'src/components/atoms/Buttons'
import LogoUrl from 'src/assets/logo-bright.png'
import MobileMockupUrl from 'src/assets/images/mobile-mockup.png'
import DesktopMockupUrl from 'src/assets/images/desktop_mockup.png'
import { MobileAppStoreBadgeBtn } from '../components/static/mobileAppStoreBadgeBtn'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'
import Shade from 'src/components/Shade'
import {
  Banner,
  MainTitle,
  BannerContentWrapper,
  BannerMobileRow,
  BoxesSection,
  BoxesTitle,
  Box,
  ButtonsRow,
  StatisticsSection,
  CircleRow,
  Circle,
  CircleText,
  CircleNumber,
  BannerMobileUser,
  MobileUserTitle,
  CompaniesSection,
  CompaniesTitle,
  TrustedCompanyBox,
  GazeleLogo,
  GazeleLogo2,
  EUFinancingLogo,
  BannerSocial,
  Logo,
  Feature,
  FeaturesSection,
  FeaturesTitle,
  HeroSection
} from 'src/components/static/home'
import { Spacer } from 'src/components/filters/components/DatesRange'
import Dialog from '@material-ui/core/Dialog'

import Feature1Url from 'src/assets/images/features_homepage/filters.jpeg'
import GazeleLogoUrl2 from 'src/assets/images/gazele_2020.png'
import EUFinancingLogoUrl from 'src/assets/images/zestawienie_znakow_ue.jpg'
import { openEUFinancingDialog } from 'src/ducks/actions'
import EUFinancingDialog from 'src/components/EUFinancingDialog'
import EuFinancingLogoLarge from 'src/components/header/components/EuFinancingLogoLarge'

export default ({
  data: { backgrounds, companyLogos, gazeleLogo, googlePlayLogo }
}) => {
  const intl = useIntl()

  const containersBackgroundFluid = findFluidOrFixedInEdgesByFileName(
    'containers',
    backgrounds.edges
  )
  const mobileUserBackgroundFluid = findFluidOrFixedInEdgesByFileName(
    'mobile-user-bg',
    backgrounds.edges
  )
  const socialBackgroundFluid = findFluidOrFixedInEdgesByFileName(
    'bg-social',
    backgrounds.edges
  )
  const gazeleLogoFluid = findSingleImageFluidOrFixed('fluid')(gazeleLogo)
  const googlePlayLogoFluid = findSingleImageFluidOrFixed('fluid')(
    googlePlayLogo
  )

  const companyLogosFluids = map(
    logo => path(['node', 'childImageSharp', 'fluid'], logo),
    companyLogos.edges
  )

  const isEUFinancingDialogOpen = useSelector(
    state => state.euFinancingDialog.open
  )
  const dispatch = useDispatch()

  return (
    <ThemeProvider>
      <Layout>
        <HeroSection>
          <div
            style={{
              display: 'flex',
              wwidth: '50%',
              'flex-direction': 'column',
              'justify-content': 'center',
              'align-items': 'center'
            }}
          >
            <MainTitle>
              <FormattedMessage id='homePage.title' />
            </MainTitle>
            <h2 style={{ 'text-align': 'center' }}>
              <strong>"Find & Go!"</strong> -{' '}
              <FormattedMessage id='homePage.titleExtended' />
            </h2>
            <Link to={routes.SIGN_UP}>
              <Button
                active
                style={{ 'margin-top': '25px', 'margin-bottom': '25px' }}
              >
                <FormattedMessage id='common.register' />
              </Button>
            </Link>
          </div>
          <div>
            <img
              style={{
                'box-shadow': '8px 33px 56px 0px rgba(66, 68, 90, 1)',
                'object-fit': 'contain'
              }}
              src={DesktopMockupUrl}
              width='100%'
            />
          </div>
        </HeroSection>
        {/* <div style={{'margin-bottom': '200px'}}>
        <MainTitle>
              <FormattedMessage id='homePage.title' />
            </MainTitle>
            <Link to={routes.SIGN_UP}>
              <Button active>
                <FormattedMessage id='common.register' />
              </Button>
            </Link>
        </div> */}
        <BoxesSection>
          <BoxesTitle>
            <FormattedMessage id='homePage.boxesSection.title' />
          </BoxesTitle>
          <Box
            image='offer'
            title={intl.formatMessage({
              id: 'homePage.boxesSection.offer.title'
            })}
            description=''
            backgroundColor='white'
            textColor='blue'
          />
          <Box
            image='booking'
            title={intl.formatMessage({
              id: 'homePage.boxesSection.booking.title'
            })}
            description={intl.formatMessage({
              id: 'homePage.boxesSection.booking.description'
            })}
            backgroundColor='blue'
            textColor='white'
          />
          <Box
            image='adds'
            title={intl.formatMessage({
              id: 'homePage.boxesSection.contracts.title'
            })}
            description=''
            backgroundColor='white'
            textColor='green'
          />
          <Box
            image='support'
            title={intl.formatMessage({
              id: 'homePage.boxesSection.support.title'
            })}
            description={intl.formatMessage({
              id: 'homePage.boxesSection.support.description'
            })}
            backgroundColor='green'
            textColor='white'
          />
          <Box
            image='notify'
            title={intl.formatMessage({
              id: 'homePage.boxesSection.orders.title'
            })}
            description=''
            backgroundColor='green'
            textColor='white'
          />
          <Box
            image='ui'
            title={intl.formatMessage({
              id: 'homePage.boxesSection.ui.title'
            })}
            description={intl.formatMessage({
              id: 'homePage.boxesSection.ui.description'
            })}
            backgroundColor='white'
            textColor='green'
          />
          <Box
            image='payments'
            title={intl.formatMessage({
              id: 'homePage.boxesSection.payments.title'
            })}
            description=''
            backgroundColor='red'
            textColor='white'
          />
          <Box
            image='comments'
            title={intl.formatMessage({
              id: 'homePage.boxesSection.tracking.title'
            })}
            description=''
            backgroundColor='white'
            textColor='blue'
          />
          <ButtonsRow>
            <Link to={routes.SIGN_UP}>
              <Button active>
                <FormattedMessage id='common.register' />
              </Button>
            </Link>
          </ButtonsRow>
        </BoxesSection>
        {/* <FeaturesSection>
          <FeaturesTitle>Poznaj najważniejsze funkcje platformy</FeaturesTitle>
          <Feature
            title='Wyszukiwanie ofert'
            description='Wyszukuj oferty wykorzystując staranie dobrane filtry.'
            image={Feature1Url}
          ></Feature>
          <Feature
            reverse
            title='Wyszukiwanie ofert'
            description='Wyszukuj oferty wykorzystując staranie dobrane filtry.'
            image={Feature1Url}
          ></Feature>
          <Feature
            title='Wyszukiwanie ofert'
            description='Wyszukuj oferty wykorzystując staranie dobrane filtry.'
            image={Feature1Url}
          ></Feature>
          <Feature
            reverse
            title='Wyszukiwanie ofert'
            description='Wyszukuj oferty wykorzystując staranie dobrane filtry.'
            image={Feature1Url}
          ></Feature>
        </FeaturesSection> */}
        <StatisticsSection>
          <CircleRow>
            <Circle>
              <CircleText>
                <FormattedMessage id='homePage.countersSection.trust.title' />
              </CircleText>
              <CircleNumber>
                <FormattedMessage id='homePage.countersSection.trust.number' />
              </CircleNumber>
              <CircleText>
                <FormattedMessage id='homePage.countersSection.trust.description' />
              </CircleText>
            </Circle>
            <Circle>
              <CircleText>
                <FormattedMessage id='homePage.countersSection.offers.title' />
              </CircleText>
              <CircleNumber>
                <FormattedMessage id='homePage.countersSection.offers.number' />
              </CircleNumber>
              <CircleText>
                <FormattedMessage id='homePage.countersSection.offers.description' />
              </CircleText>
            </Circle>
            <Circle>
              <CircleText>
                <FormattedMessage id='homePage.countersSection.targetsReached.title' />
              </CircleText>
              <CircleNumber>
                <FormattedMessage id='homePage.countersSection.targetsReached.number' />
              </CircleNumber>
              <CircleText>
                <FormattedMessage id='homePage.countersSection.targetsReached.description' />
              </CircleText>
            </Circle>
          </CircleRow>
        </StatisticsSection>
        <BannerMobileUser Tag='section' fluid={mobileUserBackgroundFluid}>
          <BannerMobileRow>
            <img src={MobileMockupUrl} style={{ height: '620px' }} />
          </BannerMobileRow>
          <BannerMobileRow>
            <MobileUserTitle id='mobile-apps'>
              <FormattedHTMLMessage id='homePage.mobileAppSectionTitle' />
            </MobileUserTitle>
            <MobileStoreSubtitle>
              <FormattedHTMLMessage id='homePage.mobileAppSectionTitleExchange' />
            </MobileStoreSubtitle>
            <MobileStoreWrapper>
              <MobileAppStoreBadgeBtn
                app='exchange'
                locale={intl.locale}
                platform='android'
              />
              <MobileAppStoreBadgeBtn
                app='exchange'
                locale={intl.locale}
                platform='ios'
              />
            </MobileStoreWrapper>
            <br />
            <MobileStoreSubtitle>
              <FormattedHTMLMessage id='homePage.mobileAppSectionTitleDriver' />
            </MobileStoreSubtitle>
            <MobileStoreWrapper>
              <MobileAppStoreBadgeBtn
                app='driver'
                locale={intl.locale}
                platform='android'
              />
              <MobileAppStoreBadgeBtn
                app='driver'
                locale={intl.locale}
                platform='ios'
              />
            </MobileStoreWrapper>
          </BannerMobileRow>
        </BannerMobileUser>
        <CompaniesSection>
          <CompaniesTitle>
            <FormattedMessage id='homePage.companiesSectionTitle' />
          </CompaniesTitle>
          {companyLogosFluids.map(logo => (
            <TrustedCompanyBox Tag='div' fluid={logo} key={logo.src} />
          ))}
          {/* <GazeleLogo Tag='section' fluid={gazeleLogoFluid} /> */}
          <GazeleLogo2 src={GazeleLogoUrl2} />
        </CompaniesSection>
        <BannerSocial Tag='section' fluid={socialBackgroundFluid}>
          <Logo src={LogoUrl} alt='logo' />
          <MobileUserTitle>
            <FormattedMessage id='homePage.joinSectionTitle' />
          </MobileUserTitle>
          <ButtonsRow>
            <Link to={routes.SIGN_UP}>
              <Button active>
                <FormattedMessage id='common.register' />
              </Button>
            </Link>
          </ButtonsRow>
        </BannerSocial>
        <EUFinancingDialog />
      </Layout>
      <EuFinancingLogoLarge />
    </ThemeProvider>
  )
}
export const query = graphql`
  {
    backgrounds: allFile(
      sort: { fields: name }
      filter: { name: { regex: "/containers|mobile-user|bg-social/" } }
    ) {
      edges {
        node {
          publicURL
          name
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    companyLogos: allFile(
      sort: { fields: name }
      filter: { name: { regex: "/company-logo-/" } }
    ) {
      edges {
        node {
          publicURL
          name
          childImageSharp {
            fluid(quality: 90, maxHeight: 300) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    gazeleLogo: file(name: { eq: "gazele_2020" }) {
      id
      childImageSharp {
        fluid(quality: 90, maxWidth: 400, maxHeight: 140) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    appStoreLogo: file(name: { eq: "btn-app-store" }) {
      id
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    googlePlayLogo: file(name: { eq: "btn-google-play" }) {
      id
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
const MobileStoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${breakpoint.sm`
    flex-direction: row;
  `}
`

const MobileStoreBadge = styled.img`
  display: flex;
  height: 82px;
  width: auto;
  margin-left: 0;
  margin-bottom: 0.5rem;
  ${breakpoint.sm`
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    // margin-bottom: 3.0rem;
  `}
`

const MobileStoreSubtitle = styled.h3`
  font-size: 1.5rem;
  color: white;
  margin-bottom: 1rem;
  text-align: center;
`
